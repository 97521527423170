import PlacementLayout from '@components/Cms/PlacementLayout'
import { loginStatusSelector, userDetailsSelector } from '@features/user/selector'
import { cartSelector } from '@features/order/selector'
import { sendHomeEvent } from '@foundation/analytics/tealium/lib'
import { getUserToken, useAnalyticsData } from '@foundation/hooks/useAnalyticsData'
import LayoutWithSidebar from '@layouts/LayoutWithSidebar'
import { IPage } from '@typesApp/cms'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Seo } from './Seo'
import { useFeatureFlag } from '@foundation/featureFlag/useFeatureFlag'
import { useDeviceType } from '@hooks/useDeviceType'
import { useSite } from '@foundation/hooks/useSite'

interface IHomeProps extends IPage {
  seoAlternateUrls: Record<string, string>
  locationOrigin: string
}

const Home: React.FC<{ homePageInitial: IHomeProps }> = ({ homePageInitial }) => {
  const { seoAlternateUrls } = homePageInitial
  const { mySite } = useSite()
  const userDetails = useSelector(userDetailsSelector)
  const isUserLoggedIn = useSelector(loginStatusSelector)
  const cart = useSelector(cartSelector)
  const { flags } = useFeatureFlag()
  const [isViewEventDone, setIsViewEventDone] = useState<boolean>(false)
  const { ...homeAnalyticsData } = useAnalyticsData('home')
  const deviceType = useDeviceType()
  const contentPlacements = homePageInitial?.contentPlacements || []
  const sidebarNav = contentPlacements.find(placement => placement.name === 'header_left_side_navigation')
  const pagePlacements = useMemo(() => contentPlacements.filter(p => p.name !== 'main_placement_1'), [])
  const topPlacementContent = useMemo(() => {
    return contentPlacements.filter(p => p.name === 'main_placement_1')
  }, [])
  const isLensBest = mySite?.xStoreCfg?.brand?.toLowerCase() === 'lensbest'

  // This is needed to prevent sending duplicate anonymous user analytics events during initial and full page reloads
  const isAnonymousUserAnalyticsTriggered = useRef<boolean>(false)

  useEffect(() => {
    const userToken = getUserToken()
    homeAnalyticsData.userToken = userToken

    const isAnonymousUser = !isUserLoggedIn && !cart

    if (!isViewEventDone && deviceType) {
      const homePageAnalyticsData = {
        ...homeAnalyticsData,
        Page_DeviceType: deviceType,
      }
      if (cart) {
        const orderCartId = cart.orderId
        sendHomeEvent(homePageAnalyticsData, orderCartId, userDetails)
        setIsViewEventDone(true)
      }

      if (isAnonymousUser && !isAnonymousUserAnalyticsTriggered.current && !cart) {
        sendHomeEvent(homePageAnalyticsData, '')
        setIsViewEventDone(true)
        isAnonymousUserAnalyticsTriggered.current = true // Mark as done to prevent double calls
      }
    }
  }, [homeAnalyticsData, userDetails, isViewEventDone, cart, isUserLoggedIn, deviceType])

  return (
    <>
      {flags.show_empty_div && <div id="ff_show_empty_div" style={{ display: 'none' }}></div>}
      <Seo>
        {homePageInitial.locationOrigin && <link rel="canonical" href={homePageInitial.locationOrigin} />}
        {seoAlternateUrls &&
          !isLensBest &&
          Object.entries(seoAlternateUrls).map(([locale, href]) => (
            <link key={locale} rel="alternate" hrefLang={locale} href={href} />
          ))}
      </Seo>
      <LayoutWithSidebar topPlacements={<PlacementLayout placements={topPlacementContent} />} sidebarNav={sidebarNav}>
        <PlacementLayout placements={pagePlacements} loading={false} contentVisibilityStart={0} />
      </LayoutWithSidebar>
    </>
  )
}

export default Home
